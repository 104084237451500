<template>
  <div class="main">
    <iframe class="iframeBox" height="100%" :src='callCenterUrl'></iframe>
  </div>
</template>
<script>
  import moment from 'moment';
  import { dealAccountNoHide } from '@/lib/format';
  import { Toast,Dialog } from 'vant';
  import Loading from "@/components/Loading";
  import { getNickName } from '@/server';
  import {setCookie,getCookie,domainUrl} from '@/lib/utils';
  export default {
    components: {
      Loading
    },
  data() {
    return {
      openId:getCookie('openId') || '',
      nickName: '',
      code:this.$route.query.code || '',
      callCenterUrl:'',
      skillGroupId: '469',
      enterpriseId: '256',
      tempStr: ''
    };
  },
  mounted(){
    
  },
  created(){
    //如果有openid
    if(this.openId){
      getNickName({
        openid: this.openId
      }).then(res=>{
        if(res.wx_code=='0'){
          if(res.cusname && res.mobile){
            this.nickName = res.cusname + res.mobile;
          }else{
            this.nickName = res.nickname;
          }
          this.tempStr = encodeURIComponent(window.JSON.stringify({
            customerId: this.openId,
            customerName: this.nickName
          }))
          this.callCenterUrl = domainUrl + "/H5/#/chat?skillGroupId="+this.skillGroupId + "&enterpriseId="+this.enterpriseId+"&account="+this.openId+"&extraData="+this.tempStr
  
        }else if(res.wx_code=='7'){
          this.getCode();
        }else{
          Toast.fail({
            message: res.msg,
            duration:'3000'
          });
        }
      })
    }else{
      this.getCode();
    }
  },
  methods: {
    getCode(){
      if(!this.code) {
        let uiCode = encodeURIComponent(domainUrl+'/cellCenter');
        window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxe40be82e1238dfe7&redirect_uri='+uiCode+'&response_type=code&scope=snsapi_userinfo&state=STATE&connect_redirect=1#wechat_redirect';         
      }else{//获取code
        getNickName({
          percode: this.code
        }).then(res=>{
          if(res.wx_code=='0'){
            setCookie('openId',res.openid);
            this.openId = res.openid; 
            if(res.cusname && res.mobile){
              // setCookie('nickName',res.cusname + res.mobile);
              this.nickName = res.cusname + res.mobile;
            }else{
              // setCookie('nickName',res.nickname);
              this.nickName = res.nickname;
            }
            this.tempStr = encodeURIComponent(window.JSON.stringify({
              customerId: this.openId,
              customerName: this.nickName
            }))
            this.callCenterUrl = domainUrl + "/H5/#/chat?skillGroupId="+this.skillGroupId + "&enterpriseId="+this.enterpriseId+"&account="+this.openId+"&extraData="+this.tempStr
          }else{
            Toast.fail({
              message: res.msg,
              duration:'3000'
            });
          }
        });
      }
    }
  },
};
</script>
<style scoped>
.main{
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.iframeBox{
  /* height: 100%; */
  border: 0;
  flex: 1;
  /* margin-bottom: 1.3rem; */
}
</style>